import './AboutSectionStyleMobile.css'
import React from 'react';
export const AboutSectionMobile=React.forwardRef((props,ref) => {
    return(
        <div className='about-body-mobile' ref={ref}>
                <div className='left-column-mobile'></div>
                 <div className='about-content-mobile'>

                 <h1 className="about-h1-mobile">About</h1>
                <div  className="p-container-mobile">
                 <p className="p-aboutUs-mobile">
                    Welcome to Arenas Couture, where elegance meets individuality in
                    the world of bespoke fashion. I'm Nixon Arenas, the visionary
                    behind this couture venture that transforms dreams into exquisite
                    realities.
                    &nbsp;
                </p>


                <p className="p-aboutUs-mobile">
                    At Arenas Couture, we believe in pushing the boundaries of
                    traditional fashion. What sets us apart is our commitment to
                    creating not just garments, but wearable art that tells a story.
                    Every stitch, every detail, and every design is infused with
                    passion and precision.
                    &nbsp;
                </p>

                <b className="indulgeInThe-mobile">
                    Indulge in the extraordinary. <br></br>Indulge in Arenas Couture.
                </b>
                </div>
                

                 </div>
               
            </div>

    );
});