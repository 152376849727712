import './ServiceSectionStyleMobile.css'
import wedRing from '../../../assets/home/section-service/icons/card-wed-icon.png'
import gownIcon from '../../../assets/home/section-service/icons/card-gown-icon.png'
import fashionIcon from '../../../assets/home/section-service/icons/card-fashion-icon.png'
import menIcon from '../../../assets/home/section-service/icons/card-men-icon.png'
export const ServiceSectionMobile = () =>{
    return(

        <div className="ServiceBodyMobile">
          
            <div className="ServiceTitleMobile"> <h1>Services</h1>  </div>
            <div className="service-cards-mobile">

                <div className="cardMobile" id="wedMobile">
                    <div className="card-content-mobile">
                        <div className="wed-circle-mobile"> 
                            <img  className="wed-logo-mobile"  alt="" src={wedRing}/>
                        </div>
                        <h2 className="wed-h2-mobile">Wedding</h2>
                        <div className="card-p-container-mobile">
                            <p className="card-p-mobile">{`Bridal elegance custom gowns `}</p>
                        </div>
                    </div>
                </div>

                <div className="cardMobile" id="gownMobile">
            
                    <div className="card-content-mobile">
                        <div className="gown-circle-mobile">
                            <img  className="gown-logo-mobile"  alt="" src={gownIcon}/>
                        </div>
                        <h2 className="gown-h2-mobile">Event Gown</h2>
                        <div className="card-p-container-mobile">
                            <p className="card-p-mobile">{`Evening glamour for special occasions. `}</p>
                        </div>
                        


                    </div>

                </div>
                <div className="cardMobile" id="custMobile">
                
                    <div className="card-content-mobile">
                        <div className="fashion-circle-mobile ">
                            <img  className="gown-logo-mobile"  alt="" src={fashionIcon}/>
                        </div>
                        <h2 className="fashion-h2-mobile">Custom Fashion</h2>
                        <div className="card-p-container-mobile">
                            <p className="card-p-mobile">{`Your unique style, tailored garments.`}</p>
                        </div>
                    

                    </div>
                    

                </div>
                <div className="cardMobile" id="menMobile">
            
                    <div className="card-content-mobile">
                        <div className="men-circle-mobile ">
                            <img  className="gown-logo-mobile"  alt="" src={menIcon}/>
                        </div>
                        <h2 className="men-h2-mobile">For Men</h2>
                        <div className="card-p-container-mobile">
                            <p className="card-p-mobile">{`Dapper suits and accessories.`}</p>
                        </div>
                        


                    </div>
                    
                </div>




            </div>
    
    </div>
    );
}