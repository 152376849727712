import './TestimoniesSectionStyleMobile.css'

export const TestimoniesSectionMobile= ()=>{
    return(

        <div className='testimonies-body-mobile'>
             <div className='testimonies-content-mobile'>
               <div className='testimonies-title-mobile'> <h1 className='h1-testimonies-mobile'>Testimonies/Feeedbacks</h1>  </div> 

                <div className= "testimonies-cards-mobile">  

                  
                <Card feedback="“I appreciate your effort in making such an amazing gown, thank you so much for making me pretty.”" 
                author="" ></Card>

                <Card feedback="“Thank you sissy Nixon Arenas for my very beautiful wedding gown. 
                My mother said I did not fail in choosing who would make my dream wedding gown. Highly recommended!”"
                 author="Donna Mae Bautista"></Card>

                <Card feedback="“A huge Thank you for everything that you have done for me.
                 You have really made this the best experience ever. Your friendliness and professionalism is second to none - 
                 I couldnt have asked for anything more. I am absolutely in love with my birthday gown. You are so so so talented in what you do. I really enjoyed Wearing it.”"
                  author=""></Card>
                  
                        
                
                </div>
            </div>

        </div>
    )

}

const Card = (prop) =>{
    return(
    <div className = "card-feedback-mobile">

        <div className='feedback-content-mobile' >
            <p className='p-feedback-mobile'>
                {prop.feedback} 
                <br/>
                <br/>
                {prop.author !== "" ?   "-" +prop.author : ""} 
            </p> 
        </div>

    </div>
);
}