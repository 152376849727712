
import React from 'react';
import './WorksSectionStyleMobile.css'
import gownPic from '../../../assets/home/section-works/gown1.png'
import gownPic2 from '../../../assets/home/section-works/gown2.png'
import gownPic3 from '../../../assets/home/section-works/test1.jpg'
import gownPic4 from '../../../assets/home/section-works/test2.jpg'
import gownPic5 from '../../../assets/home/section-works/test3.jpg'
import gownPic6 from '../../../assets/home/section-works/test4.jpg'
import gownPic7 from '../../../assets/home/section-works/test5.jpg'

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
export const WorskSectionMobile=React.forwardRef((props,ref) => {

    return(
        <div className='works-body-mobile' ref={ref}>
            <div className='works-content-mobile'>
              <h1 className='h1-works-mobile'>Works</h1>
                <div className='gownPic-container-mobile'>
                <Carousel className='gownPic-mobile' 
                 emulateTouch="true"
                 infiniteLoop="true"
                 showStatus="false"
                 showArrows="true"
                 width={"60vw"}
                 >

               {/*
                <div>
                    <img src={gownPic} />
                    
                </div>
                <div>
                    <img src={gownPic2} />
                </div>
                */}
                <div>
                    <img src={gownPic3}/>
                </div>
                <div>
                    <img src={gownPic4}/>
                </div>
                <div>
                    <img src={gownPic5} />
                </div>
                <div>
                    <img src={gownPic6} />
                </div>
                <div>
                    <img src={gownPic7} />
                </div>
                
            </Carousel>
                </div>
             
                
              
            </div>
        </div>
    )
    
});