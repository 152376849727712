import React from 'react';

import './FooterStyle.css'
import footerLogo from '../../assets/home/section-footer/logo-white.png'
export const Footer  = React.forwardRef((props,ref) => {
   
    return(
        
        <FooterDesktop ref={ref}></FooterDesktop>
       
    );
}
);




const FooterDesktop =React.forwardRef(({},ref)=>{
    return(
        <footer>
            <div className='footer-body' ref ={ref}>
                <div className='footer-content'>
                    
                    <ContactContent></ContactContent>
                    <LogoContent></LogoContent>
                    <AvailabilityContent></AvailabilityContent>
                </div>
            </div>
        </footer>
    );
});

const ContactContent = ()=> {
    const redirectToGmail=()=>{
        
        window.location.href = 'https://mail.google.com/mail/u/0/?fs=1&to=nixonarenas@gmail.com&tf=cm';
    }
    const redirectToFB=()=>{
        
        window.location.href = 'https://www.facebook.com/nixonshon.artajosarenas';
    }
    return(

        <div className='contact-content'>

            <h3 className='h3-Footer'>Contact Information:</h3>
            <ul className='contact-bullets'>
                <li  onClick={redirectToGmail} >Email:  nixonArenas@gmail.com</li>
                <li  onClick={redirectToFB}>FB: Nixon Shon Artajos Arenas</li>
                <li>Phone number: 0926 162 8606</li>
                <li>Business Address: Sta. Catalina, Ilocos Sur</li>
            </ul>

        </div>
    );
}

const LogoContent = ()=> {
    return(

        <div className='logo-content'>
            
            <img className='logo-image' src={footerLogo}></img>
            <div className='p-copyRight'>
                <p>©Copyright 2023</p>
            </div>
        </div>
    );
}

const AvailabilityContent = ()=> {
    return(

        <div className='availability-content'>
            <h3 className='h3-Footer'>
            Availability of Services
            </h3>
            <p>
                "The availability of specific designs or services featured in this portfolio is subject to the current workload and schedule of Nixon Arenas Couture.
                 For inquiries about the availability of our services, please contact us directly."
            </p>
        </div>
    );
}