import "./NavBarStyle.css"

import logo from '../../assets/home/section-navbar/navLogo.png';
import fbLogo from'../../assets/home/section-navbar/icons/nav-fb-icon.png';
import callLogo from '../../assets/home/section-navbar/icons/nav-call-icon.png';
import emailLogo from '../../assets/home/section-navbar/icons/nav-email-icon.png';





export const NavBar = ({scrollToAbout, scrollToContact}) => {
    


    return (
     
        <NavBarDesktop scrollToAbout={scrollToAbout} scrollToContact={scrollToContact}></NavBarDesktop>
       
    );
}



//For Desktop and Table
const NavBarDesktop = ({scrollToAbout,scrollToContact}) => {
    const redirectToGmail=()=>{
        
        window.location.href = 'https://mail.google.com/mail/u/0/?fs=1&to=nixonarenas@gmail.com&tf=cm';
    }

    return (
        <div className="navbar-bg">
          <div className="navbar-content">
           
            <img src={logo} className="logo"></img>
           
            <div className="navbar-txt">
                <ul>
                    <li><a href="#home" >Home</a></li>
                    <li><a href="#home" onClick={scrollToAbout} >About</a></li>
                    <li><a href="#home" onClick={scrollToContact} >Contact</a></li>
                </ul>
            </div>
            <div className="navbar-icons">
                 <ul>
                    <li> <a  href="https://www.facebook.com/nixonshon.artajosarenas"> <img src={fbLogo} className="fbLogo"/> </a></li>
                    <li> <a> <img  style={{ cursor: 'pointer' }} src={callLogo}  onClick={scrollToContact}className="callLogo"/> </a></li>
                    <li> <a> <img  style={{ cursor: 'pointer' }} src={emailLogo} onClick={redirectToGmail} className="emailLogo"/> </a></li>
                </ul>
            </div>
          </div>
        </div>
    );
}


