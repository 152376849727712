import React from 'react';
import './AboutSectionStyle.css'; 
import bgImg from '../../assets/home/section-about/about-bg-img.png'

export const AboutSection =React.forwardRef((props,ref) => {
    return (
            <div className='about-body' ref={ref}>
                <div className='left-column'></div>
                 <div className='about-content'>

                 <h1 className="about-h1">About</h1>
                <div  className="p-container">
                 <p className="p-aboutUs">
                    Welcome to Arenas Couture, where elegance meets individuality in
                    the world of bespoke fashion. I'm Nixon Arenas, the visionary
                    behind this couture venture that transforms dreams into exquisite
                    realities.
                    &nbsp;
                </p>

                <p className="p-aboutUs">
                    At Arenas Couture, we don't just create garments; we craft
                    experiences. We specialize in a range of services that redefine
                    luxury and style. Our expertise lies in crafting timeless
                    masterpieces for every milestone in your life.
                    &nbsp;
                </p>

                <p className="p-aboutUs">
                    At Arenas Couture, we believe in pushing the boundaries of
                    traditional fashion. What sets us apart is our commitment to
                    creating not just garments, but wearable art that tells a story.
                    Every stitch, every detail, and every design is infused with
                    passion and precision.
                    &nbsp;
                </p>

                <b className="indulgeInThe">
                    Indulge in the extraordinary.<br></br>Indulge in Arenas Couture.
                </b>
                </div>
                

                 </div>
                 
            </div>
           


    );
    
});
