

import React, {useRef, useEffect, useState} from 'react'
import './App.css';
import { NavBar } from './components/HomePage/NavBar';
import { NavBarMobile } from './components/HomePage/Mobile/NavBarMobile';
import { Footer } from './components/HomePage/Footer';
import {HomePage} from './components/HomePage'
import { HomePageMobile } from './components/HomePage/Mobile/HomePageMobile';
import { FooterMobile } from './components/HomePage/Mobile/FooterMobile';




function App() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(()=>{
    const mobileMediaQuery = window.matchMedia('(max-width: 1024px)');
    console.log(mobileMediaQuery);
    const handleMediaQueryChange = () =>  {
      setIsMobile(mobileMediaQuery.matches);
    };
  
    handleMediaQueryChange();
    mobileMediaQuery.addListener(handleMediaQueryChange)
    return () =>  mobileMediaQuery.removeListener(handleMediaQueryChange);
  },[]);
 


  const aboutRef  = useRef(null);
  const contactRef  = useRef(null);
  const worksRef = useRef(null);



  const scrollToAbout = () =>{
    if(aboutRef.current){
      aboutRef.current.scrollIntoView({behavior: 'smooth'});
    }

  }

  const scrollToContact = () => {
    if (contactRef.current) {
      contactRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }
 
  const scrollToWorks = () => {
    if (worksRef.current) {
      worksRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }

  return (
    <div className="App">
      { console.log(isMobile)}
      
      {!isMobile ?  
        <NavBar scrollToAbout= {scrollToAbout} scrollToContact={scrollToContact} ></NavBar>  : 
        <NavBarMobile  scrollToAbout= {scrollToAbout} scrollToContact={scrollToContact}></NavBarMobile>
      }
      {!isMobile ?
        <HomePage aboutRef = {aboutRef} worksRef = {worksRef} scrollToWorks={scrollToWorks} scrollToContact={scrollToContact} isMobile={isMobile}></HomePage>:
        <HomePageMobile aboutRef = {aboutRef} worksRef = {worksRef} > </HomePageMobile>
      }
        {!isMobile ?
         <Footer ref={ contactRef }></Footer>:
         <FooterMobile  ref={ contactRef }></FooterMobile>
     }
        
    
    </div>
  );
}

export default App;
