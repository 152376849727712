

import { LogoSection } from './LogoSection';
import {ServiceSectionMobile} from './ServiceSectionMobile'
import { AboutSectionMobile } from './AboutSectionMobile';
import { WorskSectionMobile } from './WorksSectionMobile';
import { TestimoniesSectionMobile } from './TestimoniesSectionMobile';

export const HomePageMobile =({aboutRef,worksRef})=>{
    return(
        <div>
            <LogoSection></LogoSection>
            <ServiceSectionMobile></ServiceSectionMobile>
            <AboutSectionMobile ref={aboutRef} ></AboutSectionMobile>
            <WorskSectionMobile ref={worksRef}></WorskSectionMobile>
            <TestimoniesSectionMobile></TestimoniesSectionMobile>
        </div>
    )
}