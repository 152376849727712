import "./ServicesSectionStyle.css";
import wedCardBG from '../../assets/home/section-service/cardsBG/card-bg-wed.jpg'
import gownCardBG from '../../assets/home/section-service/cardsBG/card-bg-gown.jpg'
import fashionCardBG from '../../assets/home/section-service/cardsBG/card-bg-fashion.jpg'
import menCardBG from '../../assets/home/section-service/cardsBG/card-bg-men.png'



import wedRing from '../../assets/home/section-service/icons/card-wed-icon.png'
import gownIcon from '../../assets/home/section-service/icons/card-gown-icon.png'
import fashionIcon from '../../assets/home/section-service/icons/card-fashion-icon.png'
import menIcon from '../../assets/home/section-service/icons/card-men-icon.png'
export const ServicesSection = () => {
    return (
        <div className="ServiceBody">
          
                <div className="ServiceTitle"> <h1>Services</h1>  </div>
                <div className="service-cards">

                    <div className="card" id="wed">
                        <div className="card-content">
                             <div className="wed-circle"> 
                                <img  className="wed-logo"  alt="" src={wedRing}/>
                             </div>
                             <h2 className="wed-h2">Wedding</h2>
                             <div className="card-p-container">
                                <p className="card-p">{`Bridal elegance custom gowns `}</p>
                            </div>
                        </div>
                    </div>

                    <div className="card" id="gown">
                 
                        <div className="card-content">
                             <div className="gown-circle">
                                 <img  className="gown-logo"  alt="" src={gownIcon}/>
                             </div>
                             <h2 className="gown-h2">Event Gown</h2>
                             <div className="card-p-container">
                                <p className="card-p">{`Evening glamour for special occasions. `}</p>
                            </div>
                            

    
                        </div>

                    </div>
                    <div className="card" id="cust">
                     
                        <div className="card-content">
                             <div className="fashion-circle ">
                                  <img  className="gown-logo"  alt="" src={fashionIcon}/>
                             </div>
                             <h2 className="fashion-h2">Custom Fashion</h2>
                             <div className="card-p-container">
                                <p className="card-p">{`Your unique style, tailored garments.`}</p>
                            </div>
                        
    
                        </div>
                        

                    </div>
                    <div className="card" id="men">
                   
                        <div className="card-content">
                             <div className="men-circle ">
                                <img  className="gown-logo"  alt="" src={menIcon}/>
                             </div>
                             <h2 className="men-h2">For Men</h2>
                             <div className="card-p-container">
                                <p className="card-p">{`Dapper suits and accessories.`}</p>
                            </div>
                            

    
                        </div>
                        
                    </div>




                </div>
           
        </div>
    );

}
