import "./NavBarStyleMobile.css"
import * as FaIcons from 'react-icons/fa'
import * as AiIcons from 'react-icons/ai';

import React, { useState } from 'react';
import { IconContext } from 'react-icons';

import { FaHouse } from "react-icons/fa6";
import { FaRegQuestionCircle } from "react-icons/fa";
import { FaFacebook } from "react-icons/fa6";
import { MdEmail } from "react-icons/md";
import { FaPhone } from "react-icons/fa";


export const NavBarMobile =({scrollToAbout, scrollToContact}) =>{
    const [isDrawerOpen, setDrawerOpen] = useState(false);

    const showSidebar = () => setDrawerOpen(!isDrawerOpen);
    
    const redirectToGmail=()=>{
        
        window.location.href = 'https://mail.google.com/mail/u/0/?fs=1&to=nixonarenas@gmail.com&tf=cm';
    }

   
    return(
        <IconContext.Provider value={{ color: "white" }}>
            <div className='navbar'>
            
                <FaIcons.FaBars onClick={showSidebar} className="burger-icon"/>
                <div className={isDrawerOpen ? 'drawer-content active' : 'drawer-content '}>
                    <ul className="navbar-x">
                        <li className="navbar-toggle">
                        <AiIcons.AiOutlineClose className="close" onClick={showSidebar}/>
                        </li>
                    </ul>
                    <ul className="drawer-text">
                    <IconContext.Provider value={{ color: "black"}}>
                        <li><FaHouse className="icons"/>  <span><a>Home</a> </span></li>
                        <li><FaRegQuestionCircle className="icons"/><span><a onClick={scrollToAbout}>About</a></span></li>
                        <li><FaFacebook className="icons" ></FaFacebook><span><a  href="https://www.facebook.com/nixonshon.artajosarenas">FB</a></span></li>
                        <li><MdEmail className="icons"/><span><a onClick={redirectToGmail}>Mail</a></span></li>
                        <li><FaPhone className="icons"/><span><a onClick={scrollToContact}>Contact</a></span></li>
                      
                        </IconContext.Provider>
                    </ul>
                </div>
                
            </div>
           
        </IconContext.Provider>

    );

    
}
