import "./HeroSectionStyle.css";


export const HeroSection = ({scrollToWorks, scrollToContact}) => {
    return (
       <div className="hero-bg">
            <div className="hero-content">
                <div className="hero-text-head">
                    <h1>Elegance Tailored For You <br/> Welcome to <br/> Nixon Arenas Couture <br/></h1>
                </div>
                <div className="hero-text-paragraph">
                    <p>
                    We invite you to explore our collections and immerse yourself in the world of Arenas Couture. Whether you're a bride-to-be, a fashion enthusiast, or someone seeking that perfect dress for a special moment, we're here to make your dreams a reality.
                    </p>
                </div>
                <didv className="hero-buttons">
                  <button className="hero-btn-bookNow hero-buttons-style" onClick={scrollToContact} >Book Now! Contact us!</button>
                  <button className="hero-btn-seeWork hero-buttons-style"  onClick={scrollToWorks}>See Works</button>
                </didv>
            </div>
            
           
       </div>
    );
}