import {HeroSection} from  './HomePage/HeroSection';
import { ServicesSection } from './HomePage/ServicesSection';
import { AboutSection } from './HomePage/AboutSection';
import { WorkSection } from './HomePage/WorksSection';
import { TestimoniesSection } from './HomePage/TestimoniesSection';




export const HomePage =( {aboutRef,worksRef,scrollToWorks,scrollToContact} )=>{

    return <div>
       <HeroSection scrollToWorks={scrollToWorks} scrollToContact={scrollToContact}></HeroSection>  
      
      <ServicesSection></ServicesSection>
        <AboutSection ref={aboutRef} ></AboutSection>
        <WorkSection ref={worksRef}></WorkSection>
        <TestimoniesSection></TestimoniesSection>
    </div>
}